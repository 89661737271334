


































































import Vue from "vue";
import ResetPasswordHeader from "@/components/auth/ResetPasswordHeader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  AUTH_LOADING,
  RESET_PASS_USER_EMAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_PAYLOAD,
  USER_STATE
} from "@/store/modules/auth/constants";
import {
  maxLength,
  minLength,
  required,
  sameAs
} from "vuelidate/lib/validators";
import { get_password_validator_regex } from "@/utils/global";
import { TranslateResult } from "vue-i18n";
import BackToLoginBtn from "@/components/auth/BackToLoginBtn.vue";
import router from "@/router";
import { UserState } from "@/store/modules/auth/interfaces";

export default Vue.extend({
  name: "CreateNewPassword",
  components: { BackToLoginBtn, ResetPasswordHeader },
  data() {
    return {
      password: null,
      confirm_password: null,
      show_password: false,
      show_confirm_password: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      auth_loading: AUTH_LOADING,
      get_reset_password_payload: RESET_PASSWORD_PAYLOAD
    }),
    password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.password?.$dirty) return errors;
      !this.$v.password?.required && errors.push(this.$t("rules.required"));
      !this.$v.password?.min && errors.push(this.$t("rules.password.min"));
      !this.$v.password?.max && errors.push(this.$t("rules.password.max"));
      !this.$v.password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      return errors;
    },
    confirm_password_field_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.confirm_password?.$dirty) return errors;
      !this.$v.confirm_password?.required &&
        errors.push(this.$t("rules.required"));
      !this.$v.confirm_password?.min &&
        errors.push(this.$t("rules.password.min"));
      !this.$v.confirm_password?.max &&
        errors.push(this.$t("rules.password.max"));
      !this.$v.confirm_password?.valid &&
        errors.push(this.$t("rules.password.combine"));
      !this.$v.confirm_password?.match &&
        errors.push(this.$t("rules.password.match"));
      return errors;
    }
  },
  methods: {
    ...mapActions("auth", {
      reset_password: RESET_PASSWORD
    }),
    ...mapMutations("auth", {
      set_reset_password_payload: RESET_PASSWORD_PAYLOAD,
      set_user_state: USER_STATE,
      set_email_for_reset_pass: RESET_PASS_USER_EMAIL
    }),
    async submit_form() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const obj = {
          password: this.password,
          reset_password_token: this.get_reset_password_payload.token,
          email: this.get_reset_password_payload.email
        };
        const response = await this.reset_password(obj);
        if (response) {
          this.set_email_for_reset_pass(null);
          this.set_reset_password_payload(null);
          this.set_user_state(UserState.RESET_PASSWORD_COMPLETED);
          await router.push("/auth/reset-password/success");
        }
      }
    }
  },
  validations() {
    return {
      password: {
        required,
        min: minLength(8),
        max: maxLength(16),
        valid: (value: string) => get_password_validator_regex().test(value)
      },
      confirm_password: {
        required,
        min: minLength(8),
        max: maxLength(16),
        valid: (value: string) => get_password_validator_regex().test(value),
        match: sameAs("password")
      }
    };
  }
});
